<template>
  <div class="class-container">
    <v-title>
      <Breadcrumb separator=">">
        <Breadcrumb-item><i class="icon-home"></i>课程与活动</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="search-box">
        <h2 class="search-title border-bottom">按条件搜索</h2>
        <Row class="search-row">
          <Col span="4">
            <span class="sub-label">省份：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.pid"
                      placeholder="请选择..."
                      @on-change="changePro">
                <Option :value="v.id"
                        v-for="v in proList"
                        :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
          </Col>
          <Col span="4" offset="1">
            <span class="sub-label">城市：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.cid"
                      placeholder="请选择..."
                      @on-change="changeCity">
                <Option :value="v.id"
                        v-for="v in cityList"
                        :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
          </Col>
          <Col span="4" offset="1">
            <span class="sub-label">门店：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.sid"
                      placeholder="请选择..."
                      @on-change="handleShopChange">
                <Option :value="v.id"
                        v-for="v in storeList"
                        :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
          </Col>
          <Col span="4" offset="1">
            <Button type="success" @click="handleShowModal('add')">新增团课</Button>
          </Col>
        </Row>
        <Row class="search-row">
          <Col span="4">
            <span class="sub-label">课程活动：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.kchd_id"
                      placeholder="请选择...">
                <Option :value="v.id"
                        v-for="v in activityType"
                        :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
          </Col>
          <Col span="4" offset="1">
            <span class="sub-label">活动时间：</span>
            <div class="sub-content">
              <DatePicker
                type="daterange"
                placeholder="请选择..."
                style="width: 100%"
                @on-change="handleDateChange"></DatePicker>
            </div>
          </Col>
          <Col span="4" offset="1">
            <span class="sub-label">主讲人：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.keyword"
                      placeholder="请选择...">
                <Option v-for="item in staffList" :value="item.true_name" :key="item.id">{{ item.true_name }}</Option>
              </Select>
            </div>
          </Col>
          <Col span="4" offset="1">
            <span class="sub-label">参与顾客：</span>
            <div class="sub-content">
              <Input v-model="searchData.member_name" placeholder="请输入顾客姓名"></Input>
            </div>
          </Col>
          <Col span="4" offset="1">
            <Button type="primary" @click="handleSearch">搜索团课</Button>
          </Col>
        </Row>
      </div>
      <div class="content-box">
        <h2 class="content-title border-bottom">
          <span>课程列表（活动人次：{{ totalPeople }}）</span>
          <div class="action-bar">
            <Button type="success" size="small" @click="handleExport">导出报表</Button>
            <Button
              type="error"
              @click="handleDelete"
              style="margin-left: 5px;"
              v-show="selectedDataItem.length > 0 && this.isAdmin"
            >批量删除</Button>
          </div>
        </h2>
        <div class="content-body">
          <Table
            :columns="classColumns"
            :data="classList"
            ref="table"
            @on-selection-change="handleDataSelect"></Table>
            <div>
              <span class="records">共 {{totalClasses}} 条记录</span>
            </div>
            <div class="page-nations-wrapper">
              <div class="page-nations">
                <Page
                  :total="totalClasses"
                  :current="searchData.page"
                  :page-size="searchData.size"
                  @on-change="changeDataPage"></Page>
              </div>
            </div>
        </div>
      </div>
    </div>
    <Modal
      v-model="showModal"
      title="课程与活动录入"
      class-name="vertical-center-modal"
      width="1000"
      @on-ok="handleModalOk"
      :loading="modalLoading"
      @on-visible-change="handleModalVisible">
      <Form :model="formData" :label-width="110">
        <Row class="search-row">
          <Col span="11">
            <Form-item label="课程活动">
              <Select v-model="formData.kchd_id">
                <Option
                  :value="type.id"
                  v-for="type in activityType"
                  :key="type.id">{{ type.name }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="11" offset="1">
            <Form-item label="活动主题">
              <Input v-model="formData.hdzt" placeholder="请输入活动主题"></Input>
            </Form-item>
          </Col>
        </Row>
        <Row class="search-row">
          <Col span="11">
            <Form-item label="活动时间">
              <DatePicker
                type="date"
                format="yyyy-MM-dd"
                placeholder="请选择活动日期"
                style="width: 50%;"
                :value="activityDate.date"
                :options="options"
                @on-change="handleModalDateChange"></DatePicker>
                <TimePicker
                type="time"
                format="HH:mm"
                placeholder="请选择活动时间"
                style="width: 50%;"
                :value="activityDate.time"
                hide-disabled-options
                :disabled-hours="[0,1,2,3,4,5,6,22,23]"
                @on-change="handleModalDateChange"></TimePicker>
                <div style="margin-top:5px;"><span style="color:red;">* </span>请选择活动日期和时间</div>
            </Form-item>
          </Col>
          <Col span="11" offset="1">
            <Form-item label="主讲人/组织人">
              <Select v-model="formData.zjr" placeholder="请选择内部员工" filterable clearable style="width: 60%;display:inline-block;">
                <Option v-for="item in staffList" :value="item.true_name" :key="item.id">{{ item.true_name }}</Option>
              </Select>
              <Input v-model="formData.zjr2" placeholder="请输入外聘老师" maxlength="30" style="width: 38%;display:inline-block;"></Input>
            </Form-item>
          </Col>
        </Row>
        <Row class="search-row">
          <Col span="11">
            <Form-item label="活动时长">
              <TimePicker
                :value="formData.hdsc"
                format="H小时m分"
                placeholder="请选择"
                style="width: 100%;"
                @on-change="handleModalDurationChange"></TimePicker>
            </Form-item>
          </Col>
          <Col span="11" offset="1">
            <Form-item label="活动地点">
              <Input v-model="formData.hddd" placeholder="请输入活动地点"></Input>
            </Form-item>
          </Col>
        </Row>
        <Row class="search-row">
          <Col span="11">
            <Form-item label="登记人">
              <span style="line-height: 34px;">{{ formData.djr || formData.djr_name }}</span>
            </Form-item>
          </Col>
        </Row>
        <Form-item label="参与顾客">
          <Select v-model="formData.cygk" filterable multiple placeholder="选择后台有档案的顾客">
            <Option v-for="item in memberList" :value="item.id" :key="item.id">{{ `${item.branch_name} ${item.true_name}` }}</Option>
          </Select>
          <Input v-model="formData.xgk" placeholder="请输入后台无档案的顾客"></Input>
        </Form-item>
        <Form-item label="复健仪器">
          <CheckboxGroup v-model="formData.fjyq">
            <Checkbox
              v-for="item in healthDevice"
              :key="item.id"
              :label="item.id">{{ item.name }}</Checkbox>
          </CheckboxGroup>
        </Form-item>
        <Form-item label="活动图片">
          <div class="demo-upload-list" v-for="(item, index) in uploadList" :key="item.name">
            <template v-if="item.status === 'finished'">
              <img :src="item.url">
              <div class="demo-upload-list-cover">
                <Icon type="ios-eye" @click.native="handlePreviewImg(uploadList, index)"></Icon>
                <Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
              </div>
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>
          <Upload action="/api/kecheng/photo/upload"
              name="photo"
              ref="upload"
              multiple
              accept=".png,.jpg,.jpeg"
              :default-file-list="defaultImgList"
              :show-upload-list="false"
              :max-size="1024 * 30"
              :format="['png','jpg','jpeg']"
              :on-format-error="handleFormatError"
              :on-success="handleUploadSuccess"
              :on-exceeded-size="handleMaxSize"
              :before-upload="handleBeforeUpload"
              style="display: inline-block;width:60px;vertical-align: middle;">
              <div class="upload-btn" v-if="actionType !== 'view'">
                <Icon type="md-add" size="25"></Icon>
                <p style="font-size:12px;line-height: 1.5;">添加图片</p>
              </div>
          </Upload>
          <div style="margin-top:5px;"><span style="color:red;">* </span>请上传签到表、两个不同角度现场照片等</div>
        </Form-item>
        <Form-item label="活动简介">
          <Input v-model="formData.jj" placeholder="请输入活动简介" type="textarea" :rows="4"></Input>
          <div style="margin-top:5px;"><span style="color:red;">* </span>请填写活动简介</div>
        </Form-item>
      </Form>
    </Modal>
    <Modal
      v-model="showPreviewModal"
      title="图片预览"
      class-name="vertical-center-modal"
      width="800">
      <div class="preview-wrapper" v-if="previewImages.length > 0">
        <Icon type="ios-arrow-back" size="26" @click="previewPrev" style="margin-right: 10px;"></Icon>
        <div class="img-wrapper">
          <img :src="previewImages[previewIndex].url" />
        </div>
        <Icon type="ios-arrow-forward" size="26" @click="previewNext" style="margin-left: 10px;"></Icon>  
      </div>
    </Modal>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import qualityService from '@/services/qualityService';
import memberService from '@/services/memberService';
import dayjs from 'dayjs';

export default {
	components: { 'v-title': vTitle },
	data() {
		return {
		    isAdmin: '',
			storeList: [],
			proList: [],
			cityList: [],
			memberList: [],
			staffList: [],
			searchData: {
				page: 1,
				size: 15,
				pid: '',
				cid: '',
				sid: '',
				keyword: '',
				member_name: '',
				kchd_id: '',
				start_time: '',
				end_time: '',
			},
			activityType: [],
			healthDevice: [],
			totalClasses: 0,
			selectedDataItem: [],
			classList: [],
			classColumns: [
				{ type: 'selection', width: 60, align: 'center' },
				{
					title: '活动时间',
					align: 'center',
					render(h, params) {
						return h('span', params.row.hdrq.substring(0, 16));
					},
				},
				{
					title: '录入时间',
					align: 'center',
					render(h, params) {
						return h('span', params.row.create_time.substring(0, 16));
					},
				},
				{ title: '课程活动', key: 'kchd_name', align: 'center' },
				{ title: '活动主题', key: 'hdzt', align: 'center' },
				{ title: '活动时长', key: 'hdsc', align: 'center' },
				{ title: '参与人数', key: 'cyrs', align: 'center' },
				{
					title: '所属门店',
					key: 'ssmd',
					align: 'center',
					render: (h, params) => {
						return params.row.ssmd.split(',').map((item) => {
							return h(
								'div',
								{
									style: { width: '100%' },
								},
								item,
							);
						});
					},
				},
				{ title: '主讲/组织人', key: 'zjr', align: 'center' },
				{ title: '录入人', key: 'lrr_name', align: 'center' },
				{
					title: '活动图片',
					key: 'staff',
					align: 'center',
					render: (h, params) => {
						const images = params.row.tp;
						return images.length > 0
							? h(
									'div',
									{
										style: { height: '80px', width: '60px', padding: '5px 0', margin: 'auto' },
									},
									[
										h('img', {
											style: { height: '100%', width: '100%' },
											attrs: { src: images[0].url },
											on: {
												click: () => this.handlePreviewImg(images, 0),
											},
										}),
									],
								)
							: '';
					},
				},
				{
					title: '活动简介',
					key: 'jj',
					align: 'center',
					render: (h, params) => {
						return h(
							'div',
							{
								style: {
									overflow: 'hidden',
									display: '-webkit-box',
									'-webkit-box-orient': 'vertical',
									'-webkit-line-clamp': 3,
								},
							},
							params.row.jj,
						);
					},
				},
				{ title: '活动地址', key: 'hddd', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						const actions = [
							h('a', {
								class: 'icon_eye',
								style: {
									marginRight: 0,
								},
								on: {
									click: () => {
										this.handleShowModal('view');
										memberService.getClass({ record_id: params.row.id }).then((data) => {
											this.formData.kchd_id = data.kchd_id;
											this.formData.hdzt = data.hdzt;
											this.formData.zjr = data.zjr;
											this.formData.zjr2 = data.zjr2;
											this.formData.hdsc = data.hdsc;
											this.formData.hddd = data.hddd;
											this.formData.djr = data.djr_name;
											this.formData.djr_id = data.djr_id;
											this.formData.xgk = data.xgk;
											this.formData.cygk = data.cygk.split(',').map((item) => +item);
											this.formData.fjyq = data.fjyq.split(',').map((item) => +item);
											this.defaultImgList = data.tp;
											setTimeout(() => {
												this.uploadList = this.$refs.upload.fileList;
											}, 500);
											this.formData.jj = data.jj;
											const hdrq = data.hdrq.split(' ');
											this.activityDate.date = hdrq[0];
											this.activityDate.time = hdrq[1];
										});
									},
								},
							}),
						];
						if (this.power.indexOf('课程管理--编辑删除记录') > -1) {
							actions.push(
								h('a', {
									class: 'icon_edit',
									style: {
										margin: '0 0 0 10px',
									},
									on: {
										click: () => {
											this.handleShowModal('edit');
											memberService.getClass({ record_id: params.row.id }).then((data) => {
												this.formData.kchd_id = data.kchd_id;
												this.formData.hdzt = data.hdzt;
												this.formData.zjr = data.zjr;
												this.formData.zjr2 = data.zjr2;
												this.formData.hdsc = data.hdsc;
												this.formData.hddd = data.hddd;
												this.formData.djr = data.djr_name;
												this.formData.djr_id = data.djr_id;
												this.formData.xgk = data.xgk;
												this.formData.cygk = data.cygk.split(',').map((item) => +item);
												this.formData.fjyq = data.fjyq.split(',').map((item) => +item);
												this.defaultImgList = data.tp;
												setTimeout(() => {
													this.uploadList = this.$refs.upload.fileList;
												}, 500);
												this.formData.jj = data.jj;
												this.formData.record_id = data.id;
												const hdrq = data.hdrq.split(' ');
												this.activityDate.date = hdrq[0];
												this.activityDate.time = hdrq[1];
											});
										},
									},
								}),
							);
						}
						return h('div', actions);
					},
				},
			],
			showModal: false,
			modalLoading: true,
			formData: {
				kchd_id: '',
				hdzt: '',
				hdrq: '',
				zjr: '',
				hdsc: '',
				hddd: '',
				djr: '',
				djr_id: '',
				lrr_id: '',
				fjyq: [],
				cygk: [],
				jj: '',
				photo_ids: '',
				xgk: '',
			},
			activityDate: {
				date: '',
				time: '',
			},
			totalPeople: 0,
			uploadList: [],
			defaultImgList: [],
			actionType: 'add',
			previewIndex: 0,
			previewImages: [],
			showPreviewModal: false,
		};
	},
	computed: {
		options() {
			return {
				disabledDate(date) {
					const today = dayjs().valueOf() - 1000;
					return date && (date.valueOf() > today || date.valueOf() < today - 86400000 * 30);
				},
			};
		},
	},
	created() {
		const list = JSON.parse(localStorage.getItem('userInfo'));
		this.formData.hddd = list.user_info.branch_name;
		this.formData.djr = list.user_info.name;
		this.formData.djr_id = list.user_info.id;
		this.formData.lrr_id = list.user_info.id;
		qualityService.getQualitySelect().then((data) => {
			this.power = list.user_info.power;
			if (this.power.indexOf('课程管理--编辑删除记录') > -1 || this.power.indexOf('卡系审核人') > -1 || this.power.indexOf('服务评价人') > -1)
		        this.isAdmin = '1'
			if (this.power.indexOf('会员管理--查看所属店面会员信息权限') === -1) {
				this.branchShow = false;
			} else {
				if (
					this.power.indexOf('会员管理--查看全国会员信息权限') === -1 &&
					this.power.indexOf('会员管理--查看所属区域会员信息权限') === -1
				) {
					this.branchShow = true;
					this.branch_id = list.user_info.branch;
					this.searchData.sid = this.branch_id[2];
					this.searchData.cid = this.branch_id[1];
					this.searchData.pid = this.branch_id[0];
					this.proName = list.user_info.pro_name;
					this.cityName = list.user_info.city_name;
					this.sName = list.user_info.branch_name;
				}
			}
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
		memberService.getClassActivity().then((data) => {
			this.activityType = data;
		});
		memberService.getHealthDevice().then((data) => {
			this.healthDevice = data;
		});
		this.handleSearch();
		this.handleShopChange();
		this.getStaffs();
	},
	mounted() {
		this.uploadList = this.$refs.upload.fileList;
	},
	methods: {
		changePro() {
			this.cityList = this.city[this.searchData.pid];
			this.searchData.cid = '';
		},
		changeCity() {
			this.storeList = this.store[this.searchData.cid];
			this.searchData.sid = '';
		},
		changeDataPage(page) {
			this.searchData.page = page;
			this.handleSearch();
		},
		handleDataSelect(selection) {
			this.selectedDataItem = selection.map((item) => item.id);
		},
		handleExport() {
			memberService.getClassList({ ...this.searchData, size: 10000, export: 1 }).then((data) => {
				const a = document.createElement('a');
				a.href = URL.createObjectURL(data);
				a.style.display = 'none';
				a.setAttribute('download', '课程与活动.xls');
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			});
		},
		handleDelete() {
			this.$Modal.confirm({
				title: '确认',
				content: '确定删除所选课程记录吗？',
				onOk: () => {
					memberService.deleteClass({ ids: this.selectedDataItem.join(',') }).then(() => {
						this.$Message.success('删除成功');
						this.handleSearch();
						this.selectedDataItem = [];
					});
				},
			});
		},
		handleSearch() {
			memberService.getClassList(this.searchData).then((data) => {
				this.classList = data.list;
				this.totalClasses = data.row_size;
				this.totalPeople = data.hdrc;
			});
		},
		handleDateChange(val) {
			if (val.every((item) => item)) {
				this.searchData.start_time = val[0];
				this.searchData.end_time = val[1];
			} else {
				this.searchData.start_time = null;
				this.searchData.end_time = null;
			}
		},
		handleUploadSuccess(response, file) {
			if (response.c === 0) {
				file.url = response.data.photo;
				file.id = response.data.id;
			}
		},
		handleMaxSize(file) {
			this.$Message.warning('文件 ' + file.name + ' 太大，不能超过 30 M。');
		},
		handleFormatError(file) {
			this.$Message.warning(
				'文件 ' + file.name + ' 格式不正确，请上传 png、jpg、jpeg 格式的图片。',
			);
		},
		handleBeforeUpload() {
			if (this.uploadList.length >= 9) {
				this.$Message.warning('最多只能上传 9 张照片');
				return false;
			}
		},
		handleModalOk() {
			this.$nextTick(() => {
				this.modalLoading = true;
			});

			if (this.actionType !== 'view') {
				if (!this.formData.kchd_id) {
					this.$Message.error('请选择课程活动');
					this.modalLoading = false;
					return;
				}

				if (!this.activityDate.date || !this.activityDate.time) {
					this.$Message.error('请选择活动日期和时间');
					this.modalLoading = false;
					return;
				}

				if (this.formData.cygk.length < 3) {
					this.$Message.error('请选择至少 3 名参与顾客');
					this.modalLoading = false;
					return;
				}

				if (!this.formData.jj.trim()) {
					this.$Message.error('请填写活动简介');
					this.modalLoading = false;
					return;
				}

				this.formData.photo_ids = this.uploadList.map((item) => item.id).join(',');
				if (!this.formData.photo_ids) {
					this.$Message.error('请上传活动图片');
					this.modalLoading = false;
					return;
				}

				memberService
					.addClass({
						...this.formData,
						zjr: this.formData.zjr,
						fjyq: this.formData.fjyq.join(','),
						cygk: this.formData.cygk.join(','),
						hdrq: `${this.activityDate.date} ${this.activityDate.time}`,
					})
					.then(() => {
						this.searchData.page = 1;
						this.handleSearch();
						this.$Message.success('课程添加成功');
					})
					.finally(() => {
						this.showModal = false;
					});
			} else {
				this.modalLoading = false;
				this.showModal = false;
			}
		},
		handleRemove(file) {
			if (this.actionType === 'edit') {
				let fileList = this.$refs.upload.fileList;
				this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
			}
		},
		handleModalDurationChange(value) {
			this.formData.hdsc = value;
		},
		handleModalDateChange(value) {
			if (value.indexOf(':') > -1) {
				this.activityDate.time = value;
			} else {
				this.activityDate.date = value;
			}
		},
		handleShopChange() {
			memberService
				.kechengUser({
					page: 1,
					size: 10000,
					is_staff: 0,
				})
				.then((data) => {
					this.memberList = data.list;
				});
		},
		getStaffs() {
			memberService
				.kechengUser({
					page: 1,
					size: 10000,
					is_staff: 1,
				})
				.then((data) => {
					this.staffList = data.list;
				});
		},
		handleShowModal(type) {
			this.actionType = type;
			this.showModal = true;
		},
		handleModalVisible(visible) {
			if (!visible) {
				this.defaultImgList = [];
				setTimeout(() => {
					this.uploadList = this.$refs.upload.fileList;
				}, 500);
				const list = JSON.parse(localStorage.getItem('userInfo'));
				this.formData = {
					kchd_id: '',
					hdzt: '',
					zjr: '',
					zjr2: '',
					hdsc: '',
					hddd: list.user_info.branch_name,
					djr: list.user_info.name,
					djr_id: list.user_info.id,
					lrr_id: list.user_info.id,
					fjyq: [],
					cygk: [],
					jj: '',
					photo_ids: '',
					xgk: '',
				};
				this.activityDate = {
					date: '',
					time: '',
				};
				document.body.style.overflow = 'auto';
			}
		},
		handlePreviewImg(images, index) {
			this.showPreviewModal = true;
			this.previewImages = images;
			this.previewIndex = index;
		},
		previewPrev() {
			if (this.previewIndex > 0) this.previewIndex--;
		},
		previewNext() {
			if (this.previewIndex < this.previewImages.length - 1) this.previewIndex++;
		},
	},
};
</script>

<style lang="less" scoped>
.search-box {
  padding-bottom: 10px;
}
.search-row {
  margin: 10px 0 0 10px;
}
.sub-label {
  float: left;
  vertical-align: middle;
  line-height: 32px;
}
.sub-content {
  margin-left: 80px;
}
.content-title {
  height: 39px;
  line-height: 39px;
  padding: 0 10px;
  background-color: #fbfbfb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.demo-upload-list {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  margin-right: 4px;
  vertical-align: middle;
}
.demo-upload-list img {
  width: 100%;
  height: 100%;
}
.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
.upload-btn {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border: 1px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
}
.preview-wrapper {
  display: flex;
  align-items: center;
  .img-wrapper {
    flex: 1;
    padding-bottom: 75%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: contain;
    }
  }
}
</style>